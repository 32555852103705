import React from 'react'

function Termine() {
  return (
    <main className="px-5  sm:px-16 sm:pt-10 pt-5 font-light">
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Termine</h1>
      <p>
        Hier veröffentlich wir die Daten für unser nächstes Konzert.
      </p>
    </main>
  )
}

export default Termine